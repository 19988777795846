import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes} from "react-router-dom";
import {Photography} from "./Photography/Page/Photography";
import Home from "./Home/Home";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);




root.render(
    <React.StrictMode>
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/photography" element={<Photography />} />
            </Routes>
        </HashRouter>
    </React.StrictMode>
);

