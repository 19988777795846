import EXPO from "../assets/EXPO.webp";
import JAVA from "../assets/JAVA.webp";
import JAVASCRIPT from "../assets/javascript.svg";
import CSHARP from "../assets/csharp.svg";
export default function DecideImage(ImageName: string) {
  switch (ImageName.toUpperCase()) {
    case "JAVA":
      return JAVA;

    case "EXPO":
      return EXPO;

    case "JAVASCRIPT":
      return JAVASCRIPT;

    case "CSHARP":
      return CSHARP;
  }
}
