import React, { useEffect } from "react";
import { AllPhotoGraphyImages } from "./AllPhotographyImages";
import "./Photography.css";
import { useSearchParams } from "react-router-dom";
import NavBar from "../../Components/NavBar/NavBar";

export function Photography() {
    /*
    *
    * */
    const ImageViewerOpen = () => {
        var a = document.getElementById("ImageViewer");
        if (a) {
            a.style.height = "100%";
        }
    };

    /*
    *
    *
    * */
    const ImageViewerClose = () => {
        var a = document.getElementById("ImageViewer");
        if (a) {
            a.style.height = "0%";
        }
    };
    /*
    *
    *
    * */
    const [ImageData, setImageData] = React.useState<any>();
    /*
    *
    * */
    const [SearchParams, setSearchParams] = useSearchParams({photoid: ""});
    /*
    *
    * */
    useEffect(() => {
        let PhotoID: any = SearchParams.get("photoid");
        if (PhotoID !== "") {
            PhotoID = parseInt(PhotoID);
            if (PhotoID >= AllPhotoGraphyImages.length || PhotoID < 0) {
                return;
            }
            setImageData({
                ID: AllPhotoGraphyImages[PhotoID]?.ID,
                ActualImage: AllPhotoGraphyImages[PhotoID]?.ActualImage,
                Description: AllPhotoGraphyImages[PhotoID]?.Description,
            });
            ImageViewerOpen();
        }
        //eslint-disable-next-line
    }, []);
    /*
    *
    * */
    return (
        <>
            <NavBar/>
            <div className="Wrapper" id="PhotographyWrapper">
                <div id="PhotographyContainer" className="flex Justify">
                    {AllPhotoGraphyImages.map((names, key) => {
                        return (
                            <img
                                key={key}
                                alt={names.Description}
                                src={require(`../assets/MinifiedImages/${names.ID}.jpg`)}
                                className="PhotographyImages"
                                loading="lazy"
                                onClick={() => {
                                    setSearchParams(
                                        (prev) => {
                                            prev.set("photoid", key.toString());
                                            return prev;
                                        },
                                        {replace: true}
                                    );
                                    setImageData({
                                        ID: names.ID,
                                        ActualImage: names.ActualImage,
                                        Description: names.Description,
                                    });

                                    ImageViewerOpen();
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <div
                id="ImageViewer"
                className="flex Justify Align"
                onClick={() => {
                    ImageViewerClose();
                    setImageData(null);
                }}
            >
                <button
                    onClick={() => {
                        ImageViewerClose();
                        setImageData(null);
                    }}
                    id="CloseButton"
                >
                    &times;
                </button>
                {<img
                    src={ImageData?.ActualImage}
                    id="ImageViewerImage"
                    alt={ImageData?.Description}
                /> ? (
                    <>
                        <div className="flex Column Justify Align">
                            <img
                                src={ImageData?.ActualImage}
                                id="ImageViewerImage"
                                alt={ImageData?.Description}
                            />
                            <span style={{marginTop: "5px"}}>{ImageData?.Description}</span>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}
