import REACT from "../../assets/React.svg";
import EXPO from "../../assets/ExpoIcon.svg";
import FIGMA from "../../assets/Figma.svg";
import JAVA from "../../assets/javaIcon.svg";
import GIT from "../../assets/GIT.svg";
import SPRINGBOOT from "../../assets/SpringBoot.svg";
import TYPESCRIPT from "../../assets/TypeScript.svg";
import SQL from "../../assets/sql.svg";

export default function TechnologyUsed() {
  return (
      <>
        <div className="flex" style={{marginTop: "50px"}}>
          <div className="flex  Column primary">
            <p className="Tag">‍💻 &nbsp; Technologies and skills</p>
            <div id={"TechnologyUsedTag"}>
              <div>
                <img
                    src={REACT}
                    alt="icon"
                    draggable={false}
                />
                <span>React</span>
              </div>
              <div>
                <img
                    src={EXPO}
                    style={{scale: "115%"}}
                    alt="icon"
                    draggable={false}
                />
                <span>Expo</span>
              </div>
              <div>
                <img
                    src={TYPESCRIPT}
                    alt="icon"
                    draggable={false}
                />
                <span>TypeScript</span>
              </div>
              <div>
                <img
                    src={GIT}
                    alt="icon"
                    draggable={false}
                />
                <span>Git</span>
              </div>
              <div>
                <img
                    src={FIGMA}
                    alt="icon"
                    draggable={false}
                />
                <span>Figma</span>
              </div>
              <div>
                <img
                    src={JAVA}
                    alt="icon"
                    draggable={false}
                    style={{scale: "145%"}}
                />
                <span>Java</span>
              </div>
              <div>
                <img
                    src={SPRINGBOOT}
                    alt="icon"
                    draggable={false}
                />
                <span>SpringBoot</span>
              </div>
              <div>
                <img
                    src={SQL}
                    alt="icon"
                    style={{scale: "125%"}}
                    draggable={false}
                />
                <span>MySQL</span>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
