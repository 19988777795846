import EMAIL from "../../assets/email.svg";
import CLIPBOARD from "../../assets/Copyclipboard.svg";
export default function ContactMe() {
  const CopyToClipboard = () => {
    navigator.clipboard.writeText("sagar.bhu777@gmail.com");
    const ToolTipElement = document.getElementById("tooltip");
    if (ToolTipElement != null) {
      ToolTipElement.style.display = "block";
    }

    setTimeout(() => {
      if (ToolTipElement != null) {
        ToolTipElement.style.display = "none";
      }
    }, 2000);
  };
  return (
    <>
      <div className="flex" style={{ marginTop: "50px", marginBottom: "40px" }}>
        <div>
          <div className="flex Column ">
            <p className="Tag">📬 &nbsp; contact me </p>
            <p className="primary ">Let's have a conversation !</p>
          </div>
          <div className="flex Column">
            <img src={EMAIL} alt="Email" />
            <p className="Tag">
              <a
                style={{ color: "lightgray" }}
                itemProp="email"
                target="_blank"
                href="mailto:sagar.bhu777@gmail.com"
                rel="noopener noreferrer"
              >
                sagar.bhu777@gmail.com
              </a>
            </p>
            <button onClick={() => CopyToClipboard()}>
              <img src={CLIPBOARD} alt="CLIPBOARD" />
            </button>
            <p
              style={{
                display: "none",
                position: "absolute",
                marginTop: "200px",
                backgroundColor: "rgba(0,0,0,0,0.688)",
              }}
              className="SmallTag"
              id="tooltip"
            >
              copied
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
