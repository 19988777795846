export let AllProjects = [
  {
    Topic: "Mahila Samuha App",
    Description:
      " Mahila samuha mobile app was a gift to malawardevi mahila samuha at their 2nd anniversary program.",
    TagOne: "React-native",
    TagTwo: "Expo",
    // Link: "https://github.com/sagarbhusal01/malawardevi-mahila-samuha",
    ImageName: "EXPO",
  },
  {
    Topic: "Docterr App",
    Description:
      "A doctor appointment mobile app created using react native framework and firebase a storage unit",
    TagOne: "react-native",
    TagTwo: "Online",
    // Link: "https://expo.dev/@sagarbhusal01/Docterr?serviceType=classic&distribution=expo-go",
    ImageName: "EXPO",
  },
  {
    Topic: "Java Table Builder",
    Description:
      " A Java Table Builder can be used to make tables in Java CLI environment with various table options to chose from.",
    TagOne: "JAVA",
    TagTwo: "CLI",
    Link: "https://github.com/sagarbhusal01/TableBuilder",
    ImageName: "JAVA",
  },

  {
    Topic: "Password-Manager",
    Description:
      "   password manager is an app created to demonstrate how password manager encrypts data and store them.",
    TagOne: "JAVA",
    TagTwo: "CLI",
    // Link: "https://github.com/sagarbhusal01/CLI-Password-Manager-java",
    ImageName: "JAVA",
  },
  {
    Topic: "YAML Editor for java",
    Description:
      " A YAML Editor for Java is a tool that can perform read and write operation on .yaml file, can return data into hash map.",
    TagOne: "JAVA",
    TagTwo: "CLI",
    Link: "https://github.com/sagarbhusal01/YAML-Editor-For-Java",
    ImageName: "JAVA",
  },

  {
    Topic: "Fingerprint-authentication",
    Description:
      " Its a small snack file to demonstrate the fingerprint-authentication system in expo react native",
    TagOne: "Expo",
    TagTwo: "React-Native",
    Link: "https://snack.expo.dev/@sagarbhusal01/fingerprint-authentication",
    ImageName: "EXPO",
  },

  {
    Topic: "Calculator App",
    Description:
      "  A calculator on web. Can be used to calculate. made just for design purpose but is functional and can be used too",
    TagOne: "HTML",
    TagTwo: "JS",
    Link: "https://calculator.sagarbhusal01.com.np",
    ImageName: "JAVASCRIPT",
  },

  {
    Topic: "Quiz Game",
    Description:
      " A online and offline trivia game made entirely on C#. It's a command line game with visual effects",
    TagOne: "C#",
    TagTwo: "CLI",
    // Link: "https://github.com/sagarbhusal01/QuizGame",
    ImageName: "csharp",
  },
  {
    Topic: "Workplace-Automation",
    Description:
      "  This bot opens all the programs that are needed to start coding. It opens those program by emulating key strokes.",
    TagOne: "JAVA",
    TagTwo: "Application",
    // Link: "https://github.com/sagarbhusal01/Workplace-Automation",
    ImageName: "JAVA",
  },
];

export const PinnedProjects = [
  {
    Topic: "Mahila Samuha App",
    Description:
      " Mahila samuha mobile app was a gift to malawardevi mahila samuha at their 2nd anniversary program.",
    TagOne: "React-native",
    TagTwo: "Expo",
    // Link: "https://github.com/sagarbhusal01/malawardevi-mahila-samuha",
    ImageName: "EXPO",
  },
  {
    Topic: "Java Table Builder",
    Description:
      " A Java Table Builder can be used to make tables in Java CLI environment with various table options to chose from.",
    TagOne: "JAVA",
    TagTwo: "CLI",
    Link: "https://github.com/sagarbhusal01/TableBuilder",
    ImageName: "JAVA",
  },
  {
    Topic: "YAML Editor for java",
    Description:
      " A YAML Editor for Java is a tool that can perform read and write operation on .yaml file, can return data into hash map.",
    TagOne: "JAVA",
    TagTwo: "CLI",
    Link: "https://github.com/sagarbhusal01/YAML-Editor-For-Java",
    ImageName: "JAVA",
  },
  {
    Topic: "Fingerprint-authentication",
    Description:
      " Its a small snack file to demonstrate the fingerprint-authentication system in expo react native",
    TagOne: "Expo",
    TagTwo: "React-Native",
    Link: "https://snack.expo.dev/@sagarbhusal01/fingerprint-authentication",
    ImageName: "EXPO",
  },
];
