import PROJECTICON from "../../assets/ProjectsIcon.svg";
import DESIGNICON from "../../assets/DesignIcon.svg";
import CODEICON from "../../assets/CodeIcon.svg";
import { TotalDateAsDesigner, TotalDateAsProgrammer } from "../../CareerData";
export default function Experience() {
  return (
    <>
      <div className="experienceContainer">
        <div className="cardcontainer">
          <div className="codecard">
            <img
              src={CODEICON}
              style={{ height: "100px", width: "100px" }}
              alt="CodeIcon"
            />
            <div style={{ lineHeight: "10px" }}>
              <p> {TotalDateAsProgrammer} years as </p>
              <h2>Programmer</h2>
            </div>
          </div>
          <div className="projectcard">
            <img
              alt="project icon"
              src={PROJECTICON}
              style={{ height: "100px", width: "100px" }}
            />
            <div style={{ lineHeight: "10px" }}>
              <p>1 Year of </p>
              <h2>Work</h2>
            </div>
          </div>
          <div className="designcard">
            <img
              alt="designcard icon"
              src={DESIGNICON}
              style={{ height: "100px", width: "100px" }}
            />
            <div style={{ lineHeight: "10px" }}>
              <p>{TotalDateAsDesigner} Years as</p>
              <h2>Designer</h2>
            </div>
          </div>
        </div>
        <div className="summarycontainer">
          <div className="developer">
            <div style={{ lineHeight: "8px" }}>
              <p>Developer</p>
              <h1>Full-Stack</h1>
            </div>
          </div>
          <div className="experience">
            <div style={{ lineHeight: "8px" }}>
              <p>Dozen of works and </p>
              <h1>Experience</h1>
            </div>
          </div>
          <div className="designer">
            <div style={{ lineHeight: "8px" }}>
              <p>Designer and</p>
              <h1>UI/UX</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
