import { AllProjects, PinnedProjects } from "../../Function/AllProjects";
import { useState } from "react";
import DecideImage from "../../Function/DecideImage";
export default function Portfolio() {
  const [SeeAll, setSeeAll] = useState<boolean>(false);
    return (
        <>
            <TopBar/>
            {SeeAll ? <AllProjectsDisplay/> : <PinnedProjectDisplay/>}

            {!SeeAll ? (
                <div
                    className="flex "
                    style={{justifyContent: "flex-end", marginRight: "5%"}}
                >
                    <div
                        onClick={() => {
                            setSeeAll(true);
                        }}
                        className="SmallTag flex"
                    >
                        Show All
                        {"\u2007"}
                    </div>
                </div>
            ) : (
                <div
                    className="flex "
                    style={{justifyContent: "flex-end", marginRight: "5%"}}
                >
                    <div
                        onClick={() => {
                            setSeeAll(false);
                        }}
                        className="SmallTag flex"
                    >
                        Show Less
                    </div>
                </div>
            )}
        </>
    );
}

const TopBar = () => {
    return (
        <div className="flex" style={{marginTop: "50px"}} id="portfolio">
            <div className="flex  Column">
                <p className="Tag">🔗 &nbsp; Portfolio</p>
                <h1 className="primary">Works and projects</h1>
            </div>
        </div>
    );
};

const PinnedProjectDisplay = () => {
    return (
        <div className="flex">
            <div className="flex projectslayout">
                {PinnedProjects.map((names, key) => {
                    return (
                        <div key={key} className="project">
                        <a href={names.Link}>
                <h3 className="primary">{names.Topic}</h3>
                <p className="tertiary">{names.Description}</p>
                <div className="flex">
                  <p className="SmallTag" style={{ marginRight: "20px" }}>
                    {names.TagOne}
                  </p>
                  <p className="SmallTag">{names.TagTwo}</p>
                </div>
                <div
                  className="flex"
                  style={{ marginBottom: "30px", marginTop: "20px" }}
                >
                  <img
                    src={DecideImage(names.ImageName)}
                    draggable={false}
                    alt="Docterr project link"
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AllProjectsDisplay = () => {
  return (
    <div className="flex">
      <div className="flex projectslayout">
        {AllProjects.map((names, key) => {
          return (
            <div key={key} className="project">
              <a href={names.Link}>
                <h3 className="primary">{names.Topic}</h3>
                <p className="tertiary">{names.Description}</p>
                <div className="flex">
                  <p className="SmallTag" style={{ marginRight: "20px" }}>
                    {names.TagOne}
                  </p>
                  <p className="SmallTag">{names.TagTwo}</p>
                </div>
                <div
                  className="flex"
                  style={{ marginBottom: "30px", marginTop: "20px" }}
                >
                  <img
                    src={DecideImage(names.ImageName)}
                    draggable={false}
                    alt={`${names.Topic}' logo`}
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
