import AboutMe from "./Components/AboutMe";
import Career from "./Components/Career";
import ContactMe from "./Components/ContactMe";
import Experience from "./Components/Experience";
import Landing from "./Components/Landing";
import Portfolio from "./Components/Portfolio";
import TechnologyUsed from "./Components/TechnologyUsed";
import HorizontalLine from "../Components/HorizontalLine";
import NavBar from "../Components/NavBar/NavBar";
export default function Home() {

  return (
    <>
      <NavBar />
      <Landing />
      <HorizontalLine />
      <Experience />
      <HorizontalLine marginTop={40} />
      <AboutMe />
      <HorizontalLine marginTop={60} />
      <TechnologyUsed />
      <HorizontalLine marginTop={60} />
      <Portfolio />
      <HorizontalLine marginTop={60} />
      <Career />
      <HorizontalLine marginTop={60} />
      <ContactMe />
    </>
  );
}
